import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cta from "../components/section-cta"
import { Link } from "gatsby"

import Thumbnail from "../assets/images/thumbnail.jpg"
import Respect from "../assets/images/community-standards/respect-badge.svg"
import Honest from "../assets/images/community-standards/honest-badge.svg"
import Fair from "../assets/images/community-standards/fair-badge.svg"
import Professional from "../assets/images/community-standards/professional-badge.svg"
import Vigilant from "../assets/images/community-standards/vigilant-badge.svg"
import bee from "../assets/images/community-standards/bee desktop.png"
import beeMobile from "../assets/images/community-standards/bee mobile.png"
import chevronDown from "../assets/images/chevron-down.svg"
import mobileIllust from "../assets/images/community-standards/mobile-bg.svg"
import chevronRight from "../assets/images/community-standards/Chevron - Right.svg"

const CommStandards = () => {
  const columns = [
    {
      id: 1,
      title: "Be Respectful",
      subtitle:
        "We are respectful of all the people within the Servbees community, of the Servbees platform, and the people behind the platform.",
      image: Respect,
      subtitleExpand: [
        "We respect people’s time, money, and skills. We do not sell, offer, or ask for products and services that may physically harm, or damage the honor and reputation of one another.",
        "We do not allow the sale or exchange of contraband, illegal substances, illicit acts, firearms and weapons, smuggled goods, regulated items such as alcohol, controlled drugs, or foreign currency.",
        "We do not allow transactions that perpetuate criminal acts, such as human or animal trafficking and skin trade. Here at Servbees, no one is above the law.",
      ],
    },
    {
      id: 2,
      title: "Be Honest and Open",
      subtitle:
        "We expect Servbees community members to be transparent about who they are, what they sell, and what service they provide.",
      image: Honest,
      subtitleExpand: [
        "We require every service provider and seller to provide as much truthful information as they can—to allow potential customers to know everything about a product, a service, and its suppliers and providers. We champion for transparency. We do not condone false advertising.",
        "We discourage PM is the key type of transactions. While communicating with a seller or service provider is permitted and encouraged, it is mandatory that the details of the item for sale, or rate of the service offered are clear and visible to everyone.",
      ],
    },
    {
      id: 3,
      title: "Be Fair",
      subtitle:
        "We shall price our goods and services sensibly, and based on what is generally the market value of our product or service.",
      image: Fair,
      subtitleExpand: [
        "We do not allow unreasonable haggling or price increase—for both products and services.",
        "On Pricing",
        "Do not use fa lse prices to attract customers. We do not condone clickbaiting. We encourage sellers and service providers to educate themselves on how to reasonably price their goods or services.",
        "On Haggling",
        "At Servbees, we allow price negotiations within the community. What we don’t allow is excessive and unreasonable bargaining. Be fair to the sellers and service providers.",
        "Everyone at Servbees are guided and protected by the DEPARTMENT ADMINISTRATIVE ORDER NO. 2 Series of 1993 of Department of Trade and Industry, under the TITLE III PROTECTION AGAINST DECEPTIVE, UNFAIR AND UNCONSCIONABLE SALES ACTS AND PRACTICES",
      ],
    },
    {
      id: 4,
      title: "Be Professional",
      subtitle:
        "We at Servbees take every transaction seriously and treat every member of the community with integrity. We regard each other as professionals.",
      image: Professional,
      subtitleExpand: [
        "If receiving an order or booking a service, make sure to complete it at a time and price that will be agreed upon. Here at, we are professionals.",
        "We do not condone false orders or requests to sellers and service providers. If an order or a booking is confirmed, that must be paid. Don’t be a scammer.",
        "Age Consideration",
        "If you are 16 or 17 years old, you are allowed to participate as a member of the community. You can offer your skills, sell items, and procure goods and services, but we will require a document expressing legal consent of your parents or legal guardian.",
      ],
    },
    {
      id: 5,
      title: "Be Vigilant",
      subtitle:
        "We protect and care for our community, in the same way we want the community to protect and care for us.",
      image: Vigilant,
      subtitleExpand: [
        "If you see a post, language, behavior, photo, service, or anything that’s offensive or illegal within our community, please report to support@Servbees.com",
        "If you are a merchant of delivering an order or providing a booked service, or a customer receiving an order or booked service, make sure to follow the health and safety protocols by DOH. For more information regarding the protocols, please click here.",
        "If we observe that a community member’s health is at risk, the transaction may be cancelled.",
        "If a seller, service provider, or customer is not following the said health and safety protocols, please report the incident to any of the email addresses below, and to your local authorities.",
      ],
    },
  ]
  const [id, setID] = useState()

  return (
    <>
      <Layout showFooter={true} className="comm-standards">
        <SEO
          pageTitle="Community Standards"
          title="Community Standards | Servbees"
          description="We uphold certain standards to ensure that the Servbees community is well-guided and safe."
          imageUrl={Thumbnail}
        />
        <div className="banner-breadcrumb">
          <div className="container">
            <Link to="/help-center" className="banner-breadcrumb-text">
              Help Center
            </Link>
            <img
              src={chevronRight}
              alt="right chevron"
              className="banner-breadcrumb-icon"
            />
            <Link to="/community-standards" className="banner-breadcrumb-text">
              Community Standards
            </Link>
            <h1>
              Servbees<span className="trademark">&#8482;</span>Community
              Standards
            </h1>
          </div>
        </div>
        <div className="section-hero">
          <div className="container">
            <div className="content-wrapper">
              <div className="content-holder">
                <div className="text-holder">
                  <h1>
                    Servbees<span className="trademark">&#8482;</span>Community
                    Standards
                  </h1>
                  <p>
                    Servbees is a community where we can all offer or look for
                    products, creations, and services. We cultivate an
                    entrepreneurial spirit that is fair, peaceful, and safe.
                    <br />
                    <br />
                    To do that, we created standards and guidelines that the
                    community must follow. We want to ensure that all members of
                    the Servbees community are guided and protected.
                  </p>
                  <div className="mobile-illustration">
                    <img src={mobileIllust} className="mobile-img" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-information">
          <div className="container">
            <div className="content-wrapper">
              {columns.map((items, index) => {
                return (
                  <>
                    <div className="content-holder" key={index}>
                      <div className="text-wrapper">
                        <div className="text-holder">
                          <h4>{items.title}</h4>
                          <p>{items.subtitle}</p>
                          <div className="btn-holder">
                            {id === items.id ? (
                              ""
                            ) : (
                              <span
                                role="presentation"
                                onClick={() => setID(items.id)}
                                className="read-more"
                              >
                                Read more&nbsp;
                                <span>
                                  <img src={chevronDown} alt="" />
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="img-holder">
                        <img src={items.image} alt="" />
                      </div>
                    </div>
                    <ul
                      className="expanded-content"
                      style={{ maxHeight: id === items.id ? "unset" : 0 }}
                    >
                      {items.subtitleExpand.map(i => {
                        return <li>{i}</li>
                      })}
                      <li
                        role="presentation"
                        onClick={() => setID()}
                        className="read-less"
                      >
                        Read Less&nbsp;
                        <span>
                          <img src={chevronDown} className="less-icon" alt="" />
                        </span>
                      </li>
                    </ul>
                  </>
                )
              })}
            </div>
          </div>
        </div>
        <div className="section-reminder">
          <div className="container">
            <div className="content-wrapper">
              <div className="content-holder">
                <div className="text-holder">
                  <p className="sub-heading">Important Reminder</p>
                  <h1>
                    We at Servbees<span className="trademark">&#8482;</span>{" "}
                    believe in accountability.
                  </h1>
                  <p>
                    Servbees reserves the right to suspend or cancel a member’s
                    account if any of these guidelinesare violated. If further
                    action is needed, Servbees will also take the appropriate
                    steps to ensure the integrity of the platform and the
                    general welfare of the members of the community.
                    <br />
                    <br />
                    In the event that an account is suspended, the member may
                    appeal for reinstatement. This is subject to the evaluation
                    and discretion of the Servbees administration.
                  </p>
                </div>
                <div className="img-holder">
                  <img src={bee} alt="bee" className="img-bee" />
                  <img src={beeMobile} alt="bee" className="img-bee-mobile" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Cta />
      </Layout>
    </>
  )
}

export default CommStandards
